.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  background-color: #1a1a2e;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.payment-area {
  color: #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  min-width: 300px;
  max-width: 500px;
}

.payment-form {
  border: 1px solid #44475a;
  background-color: #282a36;
  padding: 15px;
  border-radius: 8px;
  color: #f8f8f2 !important;
}

.payment-form input,
.payment-form textarea {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #2c346e;
  border-radius: 5px;
  background-color: #1e1e2e;
  color: #ffffff;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}

.payment-form input::placeholder,
.payment-form textarea::placeholder {
  color: #a8a8a8;
}

.payment-form input:focus,
.payment-form textarea:focus {
  outline: none;
  background-color: #2b2b3c;
  box-shadow: 0 0 5px rgba(102, 0, 255, 0.5);
}

.payment-form button {
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 5px;
  height: 30px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.payment-form button:hover {
  background-color: #6f85d3;
  transition: background-color 0.2s;
}

.payment-form button:active {
  background-color: #3b4c84;
}

.payment-form .error-message {
  color: #ee2222;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
