:root {
  --primary-400: rgba(8, 8, 8, 1);
  --accent: rgba(239, 236, 230, 1);
  --primary-200: rgba(115, 115, 115, 1);
  --primary-100: rgba(186, 186, 186, 1);
  --primary-300: rgba(51, 51, 51, 1);
  --text-regular-normal-font-family: 'DM Sans', Helvetica;
  --text-regular-normal-font-weight: 400;
  --text-regular-normal-font-size: 16px;
  --text-regular-normal-letter-spacing: 0px;
  --text-regular-normal-line-height: 150%;
  --text-regular-normal-font-style: normal;
  --heading-5-font-family: 'DM Sans', Helvetica;
  --heading-5-font-weight: 700;
  --heading-5-font-size: 24px;
  --heading-5-letter-spacing: -0.24px;
  --heading-5-line-height: 139.9999976158142%;
  --heading-5-font-style: normal;
  --text-large-bold-font-family: 'DM Sans', Helvetica;
  --text-large-bold-font-weight: 700;
  --text-large-bold-font-size: 20px;
  --text-large-bold-letter-spacing: 0px;
  --text-large-bold-line-height: 150%;
  --text-large-bold-font-style: normal;
  --text-small-normal-font-family: 'DM Sans', Helvetica;
  --text-small-normal-font-weight: 400;
  --text-small-normal-font-size: 14px;
  --text-small-normal-letter-spacing: 0px;
  --text-small-normal-line-height: 150%;
  --text-small-normal-font-style: normal;
  --screenglow: 0px 0px 100px 20px rgba(86, 108, 223, 0.2);
  --shadow-small: 0px 1px 2px 0px rgba(35, 39, 46, 0.08);
}
