.desktop {
  align-items: center;
  background-color: var(--primary-400);
  display: flex;
  flex-direction: column;
  position: relative;
}

.desktop .light {
  height: 816px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  min-width: 1440px;
}

.desktop .bgimage {
  min-width: 1vw;
  background-size: cover;
  height: auto;
}

.desktop .header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 24px 96px;
  position: relative;
  width: 100%;
}

.desktop .logo {
  height: 40px !important;
  position: relative !important;
  width: 228px !important;
}

.desktop .menu {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 56px;
  position: relative;
}

.desktop .text-wrapper {
  color: var(--accent);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .buttons {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.desktop .button {
  all: unset;
  align-items: center;
  background: linear-gradient(180deg, rgb(86, 108, 223) 100%);
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 12px 24px;
  position: relative;
}

.desktop .div {
  color: #ffffff;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.desktop .arrow {
  height: 24px;
  position: relative;
  width: 24px;
}

.desktop .hero-section {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.desktop .testimonial {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 64px 0px;
  position: relative;
  width: 100%;
}

.desktop .testimonial-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 471.5px;
}

.desktop .image {
  height: 24px;
  position: relative;
  width: 37.27px;
}

.desktop .OMG-i-cannot-belie {
  align-self: stretch;
  color: var(--accent);
  font-family: var(--heading-5-font-family);
  font-size: var(--heading-5-font-size);
  font-style: var(--heading-5-font-style);
  font-weight: var(--heading-5-font-weight);
  letter-spacing: var(--heading-5-letter-spacing);
  line-height: var(--heading-5-line-height);
  position: relative;
  text-align: center;
}

.desktop .author {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  padding: 32px 0px 0px;
  position: relative;
  width: 100%;
}

.desktop .graphic-designer {
  color: var(--primary-200);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .feature {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 88px;
  padding: 70px 144px;
  position: relative;
  width: 1440px;
}

.desktop .heading {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 0px 232px;
  position: relative;
  width: 100%;
}

.desktop .discover-the-power {
  flex: 0 0 auto;
  margin-top: -0.18px;
  position: relative;
  width: 680.79px;
}

.desktop .p {
  color: var(--primary-100);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  text-align: center;
  width: 410px;
}

.desktop .features {
  align-self: stretch;
  height: 599px;
  position: relative;
  width: 100%;
}

.desktop .overlap {
  height: 599px;
  position: relative;
  width: 1152px;
}

.desktop .overlap-group {
  height: 599px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1152px;
}

.desktop .layer {
  height: 476px;
  left: 266px;
  position: absolute;
  top: 78px;
  width: 464px;
}

.desktop .feature-2 {
  align-items: flex-start;
  border: 0.5px solid;
  border-color: #2c346e;
  border-radius: 12px;
  box-shadow: var(--shadow-small);
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 351px;
  left: 296px;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  top: 248px;
  width: 269px;
}

.desktop .img {
  height: 32px;
  position: relative;
  width: 32px;
}

.desktop .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-2 {
  align-self: stretch;
  color: var(--accent);
  font-family: var(--text-large-bold-font-family);
  font-size: var(--text-large-bold-font-size);
  font-style: var(--text-large-bold-font-style);
  font-weight: var(--text-large-bold-font-weight);
  letter-spacing: var(--text-large-bold-letter-spacing);
  line-height: var(--text-large-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.desktop .text-wrapper-3 {
  align-self: stretch;
  color: var(--primary-100);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.desktop .feature-3 {
  -webkit-backdrop-filter: blur(100px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(100px) brightness(100%);
  border: 0.5px solid;
  border-color: #2c346e;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 351px;
  left: 589px;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  top: 248px;
  width: 273px;
}

.desktop .content-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.desktop .feature-4 {
  align-items: flex-start;
  border: 0.5px solid;
  border-color: #2c346e;
  border-radius: 12px;
  box-shadow: var(--shadow-small);
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 228px;
  left: 589px;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  top: 0;
  width: 563px;
}

.desktop .feature-5 {
  -webkit-backdrop-filter: blur(50px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(50px) brightness(100%);
  border: 0.5px solid;
  border-color: #2c346e;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 351px;
  left: 0;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  top: 248px;
  width: 271px;
}

.desktop .feature-6 {
  align-items: flex-start;
  border: 0.5px solid;
  border-color: #2c346e;
  border-radius: 12px;
  box-shadow: var(--shadow-small);
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 228px;
  left: 4px;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  top: 0;
  width: 561px;
}

.desktop .feature-7 {
  -webkit-backdrop-filter: blur(42px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(42px) brightness(100%);
  border: 0.5px solid;
  border-color: #2c346e;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 351px;
  left: 881px;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  top: 248px;
  width: 271px;
}

.desktop .CTA {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 80px 96px;
  position: relative;
  width: 100%;
}

.desktop .CTA-container {
  align-items: center;
  border-radius: 24px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 48px;
  justify-content: center;
  position: relative;
}

.desktop .container {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-end;
  position: relative;
}

.desktop .content-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.desktop .unlock-the-full {
  flex: 0 0 auto;
  position: relative;
  width: 456.63px;
}

.desktop .group {
  height: 396px;
  position: relative;
  width: 415.41px;
}

.desktop .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 415px;
}

.desktop .input-feild {
  align-items: center;
  align-self: stretch;
  background-color: var(--accent);
  border: 1px solid;
  border-color: #2c346e;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 24px 16px;
  position: relative;
  width: 100%;
}

.desktop .text {
  color: var(--primary-300);
  flex: 1;
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1px;
  position: relative;
}

.desktop .input-feild-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--accent);
  border: 1px solid;
  border-color: #2c346e;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 100px;
  padding: 24px 16px;
  position: relative;
  width: 100%;
}

.desktop .primary-BTN {
  all: unset;
  align-items: center;
  background: linear-gradient(180deg, rgb(86, 108, 223) 100%);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  height: 58px;
  padding: 12px 20px;
  position: relative;
  width: 415px;
}

.desktop .button-text {
  all: unset;
  box-sizing: border-box;
  color: #ffffff;
  flex: 1;
  font-family: 'DM Sans', Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
}

.desktop .CTA-shapes {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.desktop .img-2 {
  flex: 0 0 auto;
  position: relative;
}

.desktop .overlay {
  background: linear-gradient(
    180deg,
    rgb(8, 8, 8) 0%,
    rgba(8, 8, 8, 0) 50%,
    rgb(8, 8, 8) 100%
  );
  height: 381px;
  left: 0;
  position: absolute;
  top: -6px;
  width: 375px;
}

.desktop .layer-2 {
  height: 612px;
  left: 0;
  position: absolute;
  top: -273px;
  width: 304px;
}

.desktop .footer {
  align-items: flex-start;
  background-color: var(--primary-400);
  border-bottom-style: none;
  border-color: #2c346e;
  border-left-style: none;
  border-right-style: none;
  border-top-style: solid;
  border-top-width: 0.5px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 88px;
  padding: 80px 0px 0px;
  position: relative;
  min-width: 1440px;
  width: 100%;
}

.desktop .container-2 {
  align-items: center;
  align-self: stretch;
  background-color: #2c346e;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px 160px 8px 144px;
  position: relative;
  width: 100%;
}

.desktop .sub-link-container {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 38px;
  padding: 10px 0px;
  position: relative;
}

.desktop .link {
  color: #efece6;
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
